/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

menu, ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*** START CENTURY GOTHIC ***/
/* This stylesheet generated by Transfonter (https://transfonter.org) on August 20, 2017 6:55 PM */
@font-face {
  font-family: 'Century Gothic';
  src: url(3d56fdee064a0dbfe3a2fd36d7739289.eot);
  src: local("Century Gothic Italic"), local("CenturyGothic-Italic"), url(3d56fdee064a0dbfe3a2fd36d7739289.eot?#iefix) format("embedded-opentype"), url(6c634c9e1bee6b39f9a815be906c0855.woff) format("woff"), url(f785d148bac811ddc5cd1ae165547a69.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Century Gothic';
  src: url(47e2fd8dff9b53a297d69639de12f442.eot);
  src: local("Century Gothic"), local("CenturyGothic"), url(47e2fd8dff9b53a297d69639de12f442.eot?#iefix) format("embedded-opentype"), url(303d810fafd3f42ab796e2dbc332c6e8.woff) format("woff"), url(93646b1133a470a56e5942a18ca5da20.ttf) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Century Gothic';
  src: url(bd78513fd04309cea1844752a474a3c1.eot);
  src: local("Century Gothic Bold"), local("CenturyGothic-Bold"), url(bd78513fd04309cea1844752a474a3c1.eot?#iefix) format("embedded-opentype"), url(64e68eba0e0ce50ec5ebdec74eefa18f.woff) format("woff"), url(0731ab482cf0d0bf586f0b82bf17e6f6.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Century Gothic';
  src: url(7d44aec47fddd9b1a4cfe817c9950252.eot);
  src: local("Century Gothic Bold Italic"), local("CenturyGothic-BoldItalic"), url(7d44aec47fddd9b1a4cfe817c9950252.eot?#iefix) format("embedded-opentype"), url(12b1c6f10ed620be2dfd68477d867c2d.woff) format("woff"), url(29cb19b6a07e0d65ba1604e7a1daaa80.ttf) format("truetype");
  font-weight: bold;
  font-style: italic; }

/*** END CENTURY GOTHIC ***/
/*** START FUTURA PT ***/
@font-face {
  font-family: 'Futura PT';
  src: url(1bd843db2bad55048b7454cc00f1ccba.eot);
  src: local("Futura PT Heavy"), local("FuturaPT-Heavy"), url(1bd843db2bad55048b7454cc00f1ccba.eot?#iefix) format("embedded-opentype"), url(3206241910ab9781f078d3c10535f5c1.woff) format("woff"), url(cc2e37d3c134dc895e08dce72c19dba7.ttf) format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url(6546797acac1a68aa930f164ebc8ed4e.eot);
  src: local("Futura PT Medium"), local("FuturaPT-Medium"), url(6546797acac1a68aa930f164ebc8ed4e.eot?#iefix) format("embedded-opentype"), url(c8b92be03320c21491ce36bb4cbe2886.woff) format("woff"), url(4ab635fb83b15f9e3627a53b063a97e1.ttf) format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url(378ead60c07f75347cba0636e2dee6dd.eot);
  src: local("Futura PT Medium Oblique"), local("FuturaPT-MediumObl"), url(378ead60c07f75347cba0636e2dee6dd.eot?#iefix) format("embedded-opentype"), url(42673472cb7646ac3e66e64a437128ba.woff) format("woff"), url(21d08164f47a2c1208f12a20313278bc.ttf) format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url(3b2c0fd0bc086ca1ffffddb978862499.eot);
  src: local("Futura PT Light Oblique"), local("FuturaPT-LightObl"), url(3b2c0fd0bc086ca1ffffddb978862499.eot?#iefix) format("embedded-opentype"), url(a1ad23364fa8c99ab6637798938bcbeb.woff) format("woff"), url(3ededb4d1e2635a6081f646e535734db.ttf) format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url(f24dc6ae7df8e317848f017c14dcf0f3.eot);
  src: local("Futura PT Light"), local("FuturaPT-Light"), url(f24dc6ae7df8e317848f017c14dcf0f3.eot?#iefix) format("embedded-opentype"), url(bcdac18d371a99c86ee49e6cc6f3d7e3.woff) format("woff"), url(ba102592d3130f78636bf71989467e66.ttf) format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url(9f7e599e9bc533c8af30dbd35fceaa14.eot);
  src: local("Futura PT Bold Oblique"), local("FuturaPT-BoldObl"), url(9f7e599e9bc533c8af30dbd35fceaa14.eot?#iefix) format("embedded-opentype"), url(bba37fd81a660ebec14ace6d975b2bd8.woff) format("woff"), url(d50a2015ce7603594603f2164460e9ba.ttf) format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Futura PT';
  src: url(c1c93dd4b376a2f9e9124e4be45a2535.eot);
  src: local("Futura PT Bold"), local("FuturaPT-Bold"), url(c1c93dd4b376a2f9e9124e4be45a2535.eot?#iefix) format("embedded-opentype"), url(865ce0972d3d4b924a5ea379b32ddcb5.woff) format("woff"), url(30a810fad83f1237882bde24bf9ef8a8.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT';
  src: url(995e6a395437302c6e3ffd10334d6067.eot);
  src: local("Futura PT Heavy Oblique"), local("FuturaPT-HeavyObl"), url(995e6a395437302c6e3ffd10334d6067.eot?#iefix) format("embedded-opentype"), url(c09ad887c7f6a14bcd3c611877cdc471.woff) format("woff"), url(7418c6a26469f20de159336dc1b77751.ttf) format("truetype");
  font-weight: 900;
  font-style: italic; }

/*** END FUTURA PT ***/
* {
  box-sizing: border-box; }

body {
  font-family: "Century Gothic", sans-serif;
  font-size: 15px;
  color: #000;
  background: #f6f6f6; }

.container {
  max-width: 1290px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px; }

.nulled-btn {
  background: none;
  border: none;
  font-family: inherit; }

.centered-btn {
  display: flex;
  align-items: center;
  justify-content: center; }

button {
  cursor: pointer;
  padding: 0; }

.svg-fix {
  fill: none;
  stroke: #000; }

a {
  text-decoration: none;
  color: inherit; }

img {
  max-width: 100%; }

.section {
  margin: 50px 0; }

.section-title {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px; }

.shadow {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05); }

.one-height-slider .slick-list,
.one-height-slider .slick-track,
.one-height-slider .slick-slide > div {
  display: flex !important;
  height: 100%; }

.non-visible {
  position: absolute;
  visibility: hidden; }

.drop-down-block {
  display: none; }

.drop-down-btn > svg {
  transition: transform .5s ease; }

.drop-down-btn.active > svg {
  transform: rotate(180deg); }

.sd {
  font-size: 16px; }
  .sd p:not(:first-child) {
    margin-top: 20px; }
  .sd h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px; }
  .sd h2:not(:first-child) {
    margin-top: 15px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*** START HOME INTRO ***/
.home-intro {
  height: 650px; }

.home-intro-slider {
  height: 100%; }
  .home-intro-slider .slick-list,
  .home-intro-slider .slick-track,
  .home-intro-slider .slick-slide,
  .home-intro-slider .slick-slide > div {
    height: 100%; }

.home-intro-slide {
  position: relative;
  height: 100%; }
  .home-intro-slide > .container {
    height: 100%;
    display: flex;
    align-items: center; }
  .home-intro-slide__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .home-intro-slide__content {
    position: relative;
    color: #fff;
    text-align: center;
    line-height: 1.2; }
  .home-intro-slide__title {
    font-size: 45px;
    margin-bottom: 10px; }
  .home-intro-slide__txt {
    font-size: 20px;
    line-height: 1.5; }
  .home-intro-slide__link {
    border: 1px solid #fff;
    background: #fff;
    font-family: "Futura PT", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    color: #000;
    margin-top: 25px;
    display: inline-block;
    padding: 15px 25px; }

/*** END HOME INTRO ***/
/*** START HOME CATEGORIES ***/
.home-category {
  margin: 50px 0; }

.home-categories {
  display: flex;
  flex-wrap: wrap; }
  .home-categories__item {
    flex: calc(25% - 18.75px);
    margin-right: 25px;
    margin-bottom: 25px;
    border: 1px solid #eaeaea;
    border-radius: 3px;
    background: #fff;
    min-height: 295px; }
  .home-categories__item:nth-child(4n) {
    margin-right: 0; }
  .home-categories__item-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px 20px 20px; }
  .home-categories__item-img {
    margin-top: auto;
    max-height: 215px; }
  .home-categories__item-img--top-image {
    margin-top: 0; }
  .home-categories__item-title {
    margin-top: auto;
    font-size: 18px;
    text-transform: uppercase; }

/*** END HOME CATEGORIES ***/
/*** START HOME COLLECTIONS ***/
.home-collection-slider .slick-list,
.home-collection-slider .slick-track,
.home-collection-slider .slick-slide > div {
  display: flex !important; }

.home-collection-slider .slick-slide > div {
  height: 100%; }

.home-collection-slide > .container {
  height: 100%;
  position: relative; }

.home-collection-slide__bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 3px; }

.home-collection-slide__content {
  position: absolute;
  top: 50%;
  left: 150px;
  transform: translateY(-50%); }

.home-collection-slide__upper-title {
  font-size: 16px; }

.home-collection-slide__title {
  font-size: 50px; }

/*** END HOME COLLECTIONS ***/
/*** START PRODUCT LIST ***/
.product-list {
  display: flex;
  flex-wrap: wrap; }

.product-item {
  flex: 0 1 calc(25% - 18.75px);
  margin: 0 25px 25px 0;
  position: relative;
  transition: box-shadow, transform .5s ease; }
  .product-item:nth-child(4n) {
    margin-right: 0; }
  .product-item__img-block {
    padding: 15px 20px 0 20px;
    background: #fff;
    border-radius: 3px; }
  .product-item__info-block {
    margin-top: 15px;
    text-align: center;
    padding: 0 20px; }
  .product-item__hidden-block {
    padding: 20px;
    position: absolute;
    top: calc(100% - 1px);
    visibility: hidden;
    width: 100%;
    text-align: center; }
  .product-item__collection {
    font-size: 14px;
    color: #999; }
  .product-item__price-block {
    font-size: 18px;
    font-weight: bold;
    text-transform: lowercase;
    line-height: 1.66; }
  .product-item__new-price {
    color: red; }
  .product-item__name {
    font-size: 16px;
    line-height: 1.3; }
  .product-item__property-block {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #666; }
  .product-item__property {
    border: 1px solid #666;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 16px;
    padding: 4px 8px;
    min-width: 45px;
    min-height: 26px; }
  .product-item__property:not(:last-child) {
    margin-right: 10px; }
  .product-item__size-block {
    margin-top: 15px;
    font-size: 16px;
    color: #666;
    line-height: 1.3; }
  .product-item__sizes {
    margin-top: 5px; }
  .product-item__size:not(:last-child) {
    margin-right: 10px; }
  .product-item__choose {
    margin-top: 15px;
    background: #333;
    border-radius: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 11px 52px; }

.product-list.columns-3 .product-item {
  flex: 0 1 calc(33.33333% - 16.66666px);
  margin-right: 25px; }

.product-list.columns-3 .product-item:nth-child(3n) {
  margin-right: 0; }

.product-item:hover {
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
  z-index: 10; }
  .product-item:hover .product-item__hidden-block {
    visibility: visible;
    background: #fff;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.2); }
  .product-item:hover .product-item__img-block {
    box-shadow: none; }

/*** END PRODUCT LIST ***/
/*** START COLLECTION PREVIEWS ***/
.collection-previews__slide {
  display: flex !important;
  background: #fff; }
  .collection-previews__slide-block {
    flex: 0 1 50%;
    display: flex;
    align-items: center; }
  .collection-previews__slide-img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .collection-previews__slide-content {
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: #999;
    line-height: 1.3; }
  .collection-previews__slide-title, .collection-previews__slide-subtitle {
    font-weight: bold;
    color: #000; }
  .collection-previews__slide-title {
    font-size: 20px;
    margin-bottom: 5px; }
  .collection-previews__slide-subtitle {
    font-size: 14px;
    display: block;
    margin-bottom: 10px; }
  .collection-previews__slide-link {
    display: inline-flex;
    align-items: center;
    color: #000;
    font-weight: bold;
    margin-top: 15px;
    transition: opacity .5s ease;
    padding: 5px 25px; }
    .collection-previews__slide-link > svg {
      transition: transform .5s ease;
      position: relative;
      top: 1px;
      margin-left: 10px; }
    .collection-previews__slide-link:hover {
      opacity: .75; }
      .collection-previews__slide-link:hover > svg {
        transform: translateX(5px); }

/*** END COLLECTION PREVIEWS ***/
/*** START HOME COLLECTIONS ***/
.home-collections__title {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px; }

.collections-items {
  display: flex;
  flex-wrap: wrap; }

.collections-item {
  flex: 0 1 calc(25% - 26.25px);
  margin-right: 26.25px;
  margin-bottom: 26.25px;
  height: 500px;
  position: relative; }
  .collections-item:nth-child(4n) {
    margin-right: 0; }
  .collections-item__bg {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .collections-item__title {
    position: absolute;
    bottom: 15px;
    background: #fff;
    min-width: 150px;
    text-align: center;
    padding: 9px 25px;
    border: 1px solid #ebebeb;
    font-size: 16px;
    text-transform: uppercase;
    max-width: calc(100% - 20px);
    left: 50%;
    transform: translateX(-50%);
    line-height: 1; }

/*** END HOME COLLECTIONS ***/
/*** START DROP SECTION ***/
.drop-section {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #333;
  font-weight: bold; }
  .drop-section__title {
    text-align: center;
    margin-bottom: 50px; }
  .drop-section__visible {
    position: relative; }
  .drop-section__btn {
    margin: 30px auto 0 auto;
    opacity: .6; }
    .drop-section__btn > svg {
      fill: #333;
      width: 35px;
      height: 35px; }

/*** END DROP SECTION ***/
/*** START CATALOG PAGE ***/
.catalog-header {
  display: flex;
  margin-bottom: 30px; }

.catalog-body {
  display: flex; }

.filters__hidden-btn,
.catalog-sort__title {
  padding: 8px 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px solid #ccc;
  cursor: pointer; }

.filters__hidden-btn {
  flex: 0 1 270px; }

.catalog-sort {
  display: flex;
  flex-direction: column; }

.catalog aside.filters {
  flex: 0 0 270px;
  position: relative;
  top: -7px; }

.catalog .catalog-products {
  flex: 0 1 74.4%;
  margin-left: 45px; }

.catalog-products__header {
  margin-bottom: 30px;
  display: flex;
  align-items: center; }

.catalog-sort {
  margin-left: auto; }

.filter-list {
  display: flex;
  flex-direction: column;
  font-size: 14px; }
  .filter-list .drop-btn {
    display: flex;
    width: 100%; }
  .filter-list__plus-icon, .filter-list__minus-icon, .filter-list__arrow {
    width: 14px;
    height: 14px; }
  .filter-list__drop-btn {
    padding: 8px 10px;
    font-size: 14px;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin: 20px 0 12px; }
  .filter-list > li:last-child .filter-list__drop-btn {
    margin-bottom: 0; }
  .filter-list > li:first-child .filter-list__drop-btn {
    margin-top: 0; }
  .filter-list .sub-list {
    display: none; }
  .filter-list__item > ul > li > .sub-list__title {
    padding: 8px 10px;
    display: block; }

.type-filter .sub-list__drop-btn {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.5s ease; }
  .type-filter .sub-list__drop-btn.active {
    transition-delay: 0s;
    background: #fff; }

.type-filter .sub-list__item > .sub-list {
  transition: background .5s ease; }

.type-filter .sub-list__item > .sub-list.active {
  background: #fff; }

.type-filter .sub-list__item > .sub-list > li > a {
  padding: 5px 10px 5px 35px;
  display: block;
  transition: all .2s ease; }
  .type-filter .sub-list__item > .sub-list > li > a:hover {
    background: #333;
    color: #fff; }

.type-filter .sub-list__item > .sub-list {
  padding-bottom: 3px; }

.filter-list__item--image > .sub-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.filter-list__item--image > .sub-list > li {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 49%;
  margin-bottom: 10px;
  padding-left: 8px; }

.filter-list__item--image > ul > li > .sub-list__title {
  padding-right: 0; }

.filters__advanced-btn {
  margin-top: 25px;
  border: 1px solid #b6b6b6;
  text-transform: uppercase;
  font-size: 14px;
  color: #666;
  width: 100%;
  padding: 9px 15px; }

/*** END CATALOG PAGE ***/
/*** START PRODUCTS PAGINATION  ***/
.products-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #333;
  margin-top: 30px; }
  .products-pagination__list {
    display: flex;
    align-items: center; }
  .products-pagination__btn--prev {
    margin-right: 20px; }
  .products-pagination__btn--next {
    margin-left: 20px; }
  .products-pagination__btn > svg {
    width: 16px;
    height: 16px; }
  .products-pagination__item {
    padding: 5px;
    margin-right: 15px; }
    .products-pagination__item:last-child {
      margin-right: 0; }

.catalog-page .drop-section {
  text-align: left; }

/*** END PRODUCTS PAGINATION ***/
/*** START BREADCRUMBS ***/
.breadcrumb-section {
  margin: 30px 0; }

.breadcrumb-list {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ccc; }
  .breadcrumb-list__item:not(:last-child) {
    margin-right: 10px; }
  .breadcrumb-list__separator {
    margin-left: 7px;
    position: relative;
    top: 1px; }
  .breadcrumb-list__item--current {
    color: #999; }

/*** END BREADCRUMBS ***/
/*** START COLLECTIONS PAGE ***/
.collection-item {
  display: flex;
  background: #fff;
  margin-bottom: 65px; }
  .collection-item:last-child {
    margin-bottom: 100px; }
  .collection-item__txt-block {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 18px;
    font-size: 14px; }
  .collection-item__title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px; }
  .collection-item__subtitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 25px;
    font-weight: 600;
    display: block; }
  .collection-item__description {
    color: #999; }
  .collection-item__link {
    display: inline-flex;
    align-items: center;
    margin-top: 30px;
    font-weight: 600; }
    .collection-item__link > svg {
      position: relative;
      top: 1px;
      margin-left: 5px; }

.collection-design__txt-block {
  flex: 0 1 41.2%; }

.collection-design__img-block {
  flex: 0 1 58.8%;
  display: flex; }

.collection-design__img-block > img {
  height: calc(100% + 25px);
  position: relative;
  width: calc(50% - 9px);
  object-fit: cover; }

.collection-design__img-block > img:first-child {
  margin-right: 18px;
  top: -25px; }

.collection-design--reverse {
  flex-direction: row-reverse; }
  .collection-design--reverse .collection-design__img-block > img:first-child {
    top: 0; }
  .collection-design--reverse .collection-design__img-block > img:last-child {
    top: -25px; }

.collection-sd--reverse {
  flex-direction: row-reverse; }

.collection-sd__txt-block, .collection-sd__img-block {
  flex: 0 1 50%; }

.collection-sd__img-block > img {
  height: 100%;
  object-fit: cover; }

/*** END COLLECTIONS PAGE ***/
/*** START PRODUCT PAGE ***/
.product {
  margin-bottom: 50px; }
  .product > .container {
    display: flex;
    align-items: flex-start; }

.product-view {
  max-width: 60%;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .product-view__navigation-slider {
    width: 125px;
    margin-right: 25px; }
    .product-view__navigation-slider .slick-slide {
      margin: 5px 0;
      cursor: pointer; }
    .product-view__navigation-slider .slick-list {
      margin: -5px 0; }
    .product-view__navigation-slider .product-view__navigation-slide {
      display: flex !important;
      height: 100%; }
    .product-view__navigation-slider .slick-slide > div {
      background: #fff;
      height: 120px; }
    .product-view__navigation-slider img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
    .product-view__navigation-slider .slick-arrow {
      position: absolute; }
    .product-view__navigation-slider .product-view__navigation-btn--prev {
      top: -30px;
      left: 50%;
      transform: translateX(-50%); }
    .product-view__navigation-slider .product-view__navigation-btn--next {
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg); }
  .product-view__slider {
    max-width: 600px; }
  .product-view__slide {
    padding: 50px;
    background: #fff; }

.product-description {
  margin-left: 60px;
  flex-grow: 1; }
  .product-description .product-description__header {
    padding-top: 20px; }
  .product-description .product-description__title-wrapper {
    display: flex;
    align-items: center; }
  .product-description .product-favorites {
    margin-left: auto;
    display: flex;
    align-items: center; }
  .product-description .product-title {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.3;
    margin-right: 20px; }
  .product-description .product-excerpt {
    color: #666;
    font-size: 16px;
    line-height: 1.7; }
  .product-description .product-article {
    margin-top: 10px;
    font-size: 16px; }
  .product-description .product-description__price-wrapper {
    margin: 20px 0;
    padding-top: 20px;
    border-top: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .product-description .product-price {
    font-size: 35px; }
  .product-description .add-basket {
    background: #333;
    border-radius: 3px;
    color: #fff;
    border: 1px solid #333;
    transition: all .5s ease;
    font-size: 16px;
    padding: 20px 56px; }
    .product-description .add-basket:hover {
      background: #fff;
      color: #333; }

.chars-item {
  background: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 0 15px;
  margin-bottom: 10px; }
  .chars-item:last-child {
    margin-bottom: 0; }
  .chars-item .chars-item__header {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 0; }
  .chars-item .chars-item__title {
    color: #333;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-right: auto; }
  .chars-item .chars-item__header-icon {
    transform: rotate(90deg);
    transition: all .5s ease;
    width: 15px;
    fill: #666; }
  .chars-item .drop-content {
    padding-bottom: 15px; }

.chars-item.open .chars-item__header-icon {
  transform: rotate(180deg); }

.drop-content {
  display: none; }

.chars-sizes .chars-item__title-question {
  width: 13px;
  height: 13px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
  background: #f3f3f3;
  border: 1px solid #666; }

.chars-sizes .chars-item__header-value {
  margin-left: auto;
  color: #333;
  margin-right: 20px;
  font-size: 16px; }

.chars-sizes .size-list {
  display: flex;
  flex-wrap: wrap; }

.chars-sizes .size-list__item {
  margin-right: 5px; }

.chars-sizes .size-list__btn {
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  transition: all .5s ease;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center; }

.chars-sizes .size-list__item:nth-child(9n) {
  margin-right: 0; }

.chars-sizes .drop-content__header {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }

.chars-sizes .size-popup-btn {
  margin-left: auto;
  border: none;
  background: transparent;
  border-bottom: 1px solid;
  color: #666; }

.chars-sizes .size-list__btn-txt {
  font-size: 14px;
  line-height: 1; }

.chars-sizes .drop-content__body-title {
  color: #666;
  margin-bottom: 10px; }

.chars-sizes .size-list-order .size-list__item {
  margin-bottom: 5px; }

.chars-sizes .drop-content__footer-txt {
  margin-top: 5px;
  font-size: 12px;
  line-height: 1.4; }

.chars-inserts .chars-inserts__table {
  text-align: left;
  width: 100%;
  font-size: 12px; }
  .chars-inserts .chars-inserts__table thead {
    color: #999; }
  .chars-inserts .chars-inserts__table td, .chars-inserts .chars-inserts__table th {
    padding: 3px 0; }

.chars-salons .chars-salons__label {
  display: flex;
  align-items: center; }
  .chars-salons .chars-salons__label input {
    display: none; }
  .chars-salons .chars-salons__label input:checked + .chars-salons__label-circle {
    border-color: #000; }
  .chars-salons .chars-salons__label input:checked + .chars-salons__label-circle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000;
    width: 8px;
    height: 8px;
    border-radius: 50%; }
  .chars-salons .chars-salons__label .chars-salons__label-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #999;
    margin-right: 10px;
    position: relative;
    top: 1px; }
  .chars-salons .chars-salons__label .chars-salons__label-txt {
    margin-right: 10px; }

.chars-salons .chars-salons__list-item:not(:last-child) {
  margin-bottom: 15px; }

.product-video-wrapper {
  margin-top: 40px;
  background: #fff;
  padding: 35px 20px;
  width: 100%; }
  .product-video-wrapper .product-video-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
  .product-video-wrapper .product-video-title {
    text-transform: uppercase;
    color: #333; }
  .product-video-wrapper .product-video-header > svg {
    transform: rotate(180deg); }
  .product-video-wrapper .product-video-items {
    display: flex;
    flex-wrap: wrap; }
  .product-video-wrapper .product-video-item {
    margin: 0 15px;
    max-width: calc(50% - 30px); }
  .product-video-wrapper .product-video {
    background: #f6f6f6;
    max-width: 100%;
    border-radius: 3px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05); }
  .product-video-wrapper .product-video-container {
    position: relative; }
  .product-video-wrapper .product-video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05); }
    .product-video-wrapper .product-video-btn svg, .product-video-wrapper .product-video-btn svg path {
      fill: #a0a0a0;
      stroke: #a0a0a0; }
  .product-video-wrapper .product-video-item__title {
    margin-top: 5px;
    color: #333; }

.product-variations {
  margin-top: 30px; }

.product-variations__title {
  margin-bottom: 10px; }

.product-variations-items {
  display: flex;
  flex-wrap: wrap; }

.product-variation-item {
  flex: 0 1 calc(33% - 10px);
  margin-right: 15px;
  background: #fff;
  padding: 15px;
  border-radius: 3px; }
  .product-variation-item:nth-child(3n) {
    margin-right: 0; }

.product-variation__item-img {
  border-radius: 3px;
  max-width: 100%;
  object-fit: cover; }

.related-products {
  margin-bottom: 100px; }
  .related-products .related-products__header {
    margin-bottom: 30px; }
  .related-products .related-products__title {
    text-align: center;
    font-size: 16px; }

/*** END PRODUCT PAGE ***/
.top-header {
  padding: 20px 0;
  background: #fff;
  font-size: 20px; }
  .top-header > .container {
    display: flex;
    align-items: center; }

.header-menu__btn {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 20px; }

.header-menu__btn-icon {
  margin-right: 15px; }

.header-logo {
  margin: 0 auto; }

.header-ui__list {
  display: flex;
  align-items: center; }

.header-ui__list-item:not(:last-child) {
  margin-right: 25px; }

.bottom-header {
  font-size: 15px;
  background: #e8e8e8; }

.header-nav {
  text-transform: uppercase; }

.nav-list {
  display: flex;
  align-items: center;
  justify-content: center; }
  .nav-list__item:not(:last-child) {
    margin-right: 90px; }
  .nav-list__link {
    display: inline-block;
    padding: 22px 0; }

.top-footer {
  padding-bottom: 25px;
  border-bottom: 1px solid #999; }
  .top-footer > .container {
    display: flex;
    align-items: center;
    justify-content: center; }

.footer-nav {
  font-size: 16px;
  text-transform: uppercase; }
  .footer-nav__list {
    display: flex; }
  .footer-nav__list-item {
    margin-right: 60px; }

.footer-social {
  display: flex; }
  .footer-social__item:not(:last-child) {
    margin-right: 10px; }
  .footer-social__item-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #000;
    padding: 5px; }
    .footer-social__item-link svg {
      fill: #fff;
      width: 15px;
      height: 15px; }

.footer-body {
  margin: 25px 0 35px;
  text-align: center;
  font-size: 14px; }

.footer-subscribe__title {
  margin-bottom: 20px; }

.footer-subscribe__form {
  display: inline-flex; }

.footer-subscribe__form input {
  font-size: 16px;
  background: #fff;
  border: none;
  padding: 8px 41px 8px 10px; }

.footer-subscribe__form-submit {
  background: #333;
  font-size: 16px;
  color: #fff;
  padding: 6px 11px;
  line-height: 1; }

.bottom-footer > .container {
  max-width: 670px;
  text-align: center;
  font-size: 12px;
  line-height: 1.4;
  padding-bottom: 15px; }

.bottom-footer p:last-child {
  margin-top: 30px; }
